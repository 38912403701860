import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from 'modules/shared/components/loader/loader.component';
import { RatingBarComponent } from 'modules/shared/components/rating-bar/rating-bar.component';
import { RatingStarComponent } from 'modules/shared/components/rating-bar/rating-star/rating-star.component';
import { ToolbarComponentOld } from 'modules/shared/components/toolbar-old/toolbar.component';
import { TranslateModule } from '@ngx-translate/core';
import { CoolLabelComponent } from 'modules/shared/components/cool-label/cool-label.component';
import { SlideToggleComponent } from 'modules/shared/components/slide-toggle/slide-toggle.component';
import { SystemButtonComponent } from 'modules/shared/components/system-button/system-button.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatInputModule } from '@angular/material/input';
import { SingleSelectButtonComponent } from 'modules/shared/components/single-select-button/single-select-button.component';
import { ToastMessageComponent } from 'modules/shared/components/toast-message/toast-message.component';
import { AvailabilityScheduleComponent } from 'modules/shared/components/availability-schedule/availability-schedule.component';
import { ToolbarComponent } from 'modules/shared/components/toolbar/toolbar.component';
import OverlayTemplateComponent from 'app/components/common/overlay-content/overlay-template/overlay-template.component';
import { PremiumTermsComponent } from 'modules/shared/premium-terms/premium-terms.component';
import { DateFnsModule } from 'ngx-date-fns';
import { TooltipComponent } from 'modules/shared/components/tooltip/tooltip.component';
import { ResponsiveButtonsContainerComponent } from 'modules/shared/components/responsive-buttons-container/responsive-buttons-container.component';
import { ImageDirective } from 'app/directives/image.directive';
import { TrackLabelDirective } from 'app/directives/track-label.directive';

const declarations = [
    LoaderComponent,
    RatingBarComponent,
    RatingStarComponent,
    ToolbarComponent,
    ToolbarComponentOld,
    CoolLabelComponent,
    SlideToggleComponent,
    SystemButtonComponent,
    SingleSelectButtonComponent,
    ToastMessageComponent,
    AvailabilityScheduleComponent,
    OverlayTemplateComponent,
    PremiumTermsComponent,
    TooltipComponent,
    ResponsiveButtonsContainerComponent,
];

const imports = [CommonModule, DateFnsModule, MatButtonToggleModule, MatInputModule, TranslateModule];

@NgModule({
    declarations,
    imports: [...imports, FormsModule, ReactiveFormsModule, TrackLabelDirective, ImageDirective],
    exports: [...declarations, ...imports],
})
export class SharedModule {}
