import { Component, Input, Output, EventEmitter, inject } from '@angular/core';
import { RouteType } from 'routing/route-type';
import { NavigationService } from 'app/services/navigation.service';

export enum ToolbarBorderStyle {
    normal = 'normal',
    dark = 'dark',
    none = 'none',
}

export enum ToolbarActionType {
    map = 'map',
    photos = 'photos',
    filters = 'filters',
}

@Component({
    selector: 'toolbar-old',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.base.less'],
})
export class ToolbarComponentOld {
    readonly navigationService = inject(NavigationService);

    @Input() title?: string;
    @Input() showLogo = true;
    @Input() borderStyle = ToolbarBorderStyle.none;
    @Input() leftActions: ToolbarActionType[] = [];
    @Input() rightActions: ToolbarActionType[] = [];
    @Input() trackingLabel?: string = '';

    @Output() actionButtonClick = new EventEmitter<ToolbarActionType>();

    onActionButtonClick(action: ToolbarActionType) {
        this.actionButtonClick.emit(action);
    }

    onLogoClick() {
        this.navigationService.navigate(RouteType.search, 'photo');
    }

    actionIcon(action: ToolbarActionType) {
        switch (action) {
            case ToolbarActionType.map:
                return 'map-icon';
            case ToolbarActionType.filters:
                return 'filter-icon';
            default:
                return null;
        }
    }

    actionTitle(action: ToolbarActionType) {
        switch (action) {
            case ToolbarActionType.map:
                return 'search.map';
            case ToolbarActionType.photos:
                return 'search.photos';
            case ToolbarActionType.filters:
                return 'search.filters';
            default:
                return '';
        }
    }
}
