<button [class.selected]="selected()" [class.border-dark]="borderDark()" [trackLabel]="trackLabel()">
    @if (iconLeft()) {
        <img class="content-icon" src="https://app.sitly.com/releases/20241212110401/assets/images/{{ iconLeft() }}.svg" />
    }
    @if (boldHeading()) {
        <div class="bold-heading">{{ boldHeading() }}</div>
    }
    <div class="text-container">
        <div class="title" [class.normal]="titleStyle() === 'normal'">{{ title() | translate }}</div>
        @if (subtitle()) {
            <div class="subtitle">{{ subtitle() }}</div>
        }
        <ng-content select="[subtitle]"></ng-content>
    </div>
    <div class="right-item" [class.edit]="rightIconType() === 'edit'" [class.selector]="rightIconType() === 'selector'"></div>
</button>
