import { enGB as en, nl, it, nb, es, fi, da, de, fr, pt, ms } from 'date-fns/locale';
import { format } from 'date-fns';

export const dateLanguages = { en, nl, it, nb, es, fi, da, de, fr, pt, ms };
const getDateLanguage = (localeCode: string) => {
    const defaultLanguage = dateLanguages.en;

    if (!localeCode) {
        return defaultLanguage;
    }

    const language = localeCode.substring(0, 2) as keyof typeof dateLanguages;
    return dateLanguages[language] ?? defaultLanguage;
};
export const formattedDate = (dateValue: Date | string | number, formatString: string, localeCode: string) => {
    return format(dateValue instanceof Date ? dateValue : new Date(dateValue), formatString, { locale: getDateLanguage(localeCode) });
};
