import { Component, computed, ElementRef, inject, input, ViewChildren } from '@angular/core';
import { ResponsiveButton } from 'app/components/common/overlay-content/types';
import { SystemButtonComponent } from '../system-button/system-button.component';

@Component({
    selector: '[responsive-buttons-container]',
    templateUrl: './responsive-buttons-container.component.html',
    styleUrl: './responsive-buttons-container.component.less',
})
export class ResponsiveButtonsContainerComponent {
    readonly singleButton = input(true);
    readonly data = input<ResponsiveButton[]>([]);
    readonly elementRef = inject(ElementRef);

    @ViewChildren(SystemButtonComponent) buttonElements: SystemButtonComponent[];
    readonly buttons = computed(() => this.data().sort((a, b) => this.typesOrder[a.type] - this.typesOrder[b.type]));
    private readonly typesOrder = { danger: 0, primary: 1, secondary: 2, thirdly: 3 };

    onClick(button: ResponsiveButton) {
        if (button.loadingOnClick) {
            this.buttonElements.forEach(systemBtn => {
                if (systemBtn.buttonType === button.type) {
                    systemBtn.loading = true;
                } else {
                    systemBtn.disabled = true;
                }
            });
        }

        button.action?.();
    }
}
