import { signal } from '@angular/core';

export class EnvironmentUtils {
    static get isLocalhost() {
        return window.location.origin.includes('localhost');
    }
    static get isAndroid() {
        return navigator.userAgent?.includes('Android');
    }
    static get isIos() {
        return navigator.userAgent?.includes('Mac OS');
    }
    static get isWindows() {
        return navigator.userAgent?.includes('Windows');
    }

    private static get isDesktopWindowWidth() {
        const desktopMinWidth = 768;
        return window.navigator.userAgent.includes('SamsungBrowser')
            ? window.matchMedia(`(min-width: ${desktopMinWidth}px)`).matches
            : window.innerWidth >= desktopMinWidth;
    }

    static readonly isDesktop = signal(EnvironmentUtils.isDesktopWindowWidth);

    static onResize() {
        if (EnvironmentUtils.isDesktopWindowWidth !== EnvironmentUtils.isDesktop()) {
            EnvironmentUtils.isDesktop.set(EnvironmentUtils.isDesktopWindowWidth);
        }
    }
}
