import { Component, input } from '@angular/core';
import { GA4ElementAttr } from 'app/services/tracking/types';

@Component({
    selector: 'single-select-button',
    templateUrl: './single-select-button.component.html',
    styleUrls: ['./single-select-button.component.less'],
})
export class SingleSelectButtonComponent {
    readonly selected = input(false);
    readonly title = input.required<string>();
    readonly titleStyle = input<'bold' | 'normal'>('bold');
    readonly subtitle = input<string | undefined>(undefined);
    readonly iconLeft = input<string | undefined>(undefined);
    readonly boldHeading = input<string | number | undefined>(undefined);
    readonly rightIconType = input<'selector' | 'edit'>('selector');
    readonly trackLabel = input<GA4ElementAttr | undefined>(undefined);
    readonly borderDark = input(false);
}
