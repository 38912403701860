@for (btn of buttons(); track btn.type) {
    <system-button
        [buttonType]="btn.type"
        [class.single-button]="buttons().length === 1 && !btn.stretch"
        (click)="onClick(btn)"
        [iconRight]="btn.iconRight"
        [trackLabel]="btn.trackLabel"
    >
        {{ btn.title | translate }}
    </system-button>
}
